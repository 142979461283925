<template>
	<v-sheet class="customer leads-container" style="height: calc(100vh - 88px)">
		<v-layout>
			<v-flex class="py-0">
				<div style="min-width: 150px; max-width: 250px">
					<v-select
						v-model="status"
						class="listing-select"
						hide-details
						:disabled="pageLoading"
						:items="customerStatus"
						solo
						@change="statusFilterRows($event)"
						:menu-props="{ offsetY: true, contentClass: 'rounded-lg' }"
					>
						<template #item="{ item }">
							<div class="d-flex align-center py-1">
								<div class="mr-2" style="min-width: 72px !important">
									<v-chip
										size="26"
										class="px-2"
										dark
										:color="item.status_color"
										:text-color="item.textcolor"
									>
										<!-- 100-36, 1000-43, 10000-51, 100000-58, 1000000-65 , 72 item.status_count -->
										{{ item.status_count }}
									</v-chip>
								</div>
								<div class="fw-600 text-uppercase">{{ item.text }}</div>
							</div>
						</template>
					</v-select>
				</div>
			</v-flex>

			<v-flex
				v-if="selected.length > 0"
				class="py-0 my-auto d-flex justify-content-start flex-row-reverse"
				style="max-width: max-content"
			>
				<v-menu
					bottom
					left
					origin="center center"
					transition="slide-y-transition"
					rounded="0"
					offset-y
					max-height="400px"
					content-class="white-background"
					class="mr-2"
				>
					<!-- :close-on-content-click="false" -->
					<template v-slot:activator="{ on, attrs }">
						<v-btn
							depressed
							color="blue darken-4"
							class="text-white"
							tile
							:disabled="pageLoading"
							v-bind="attrs"
							v-on="on"
						>
							<v-icon left>mdi-chevron-down</v-icon>
							Bulk Action
						</v-btn>
					</template>
					<v-list class="py-0">
						<template v-for="(item, i) in listingBulkAction">
							<v-list-item link :key="i + '_' + 'customer_bulk_action'" @click="doAction(item.value)">
								<v-list-item-title>
									<v-icon v-if="item.icon" left :color="item.color">{{ item.icon }}</v-icon>
									{{ item.title }}
								</v-list-item-title>
							</v-list-item>
							<v-divider v-if="listingBulkAction[i + 1]" :key="`d` + i" class="m-0 p-0"></v-divider>
						</template>
					</v-list>
				</v-menu>
				<v-btn
					depressed
					:disabled="pageLoading"
					tile
					v-on:click="clearSelection"
					color="red darken-2"
					class="text-white ml-2"
				>
					<v-icon left> mdi-close </v-icon>
					Clear Selections
				</v-btn>
			</v-flex>

			<v-flex v-else class="py-0 my-auto d-flex text-right listing-right justify-content-end">
				<v-flex
					v-if="
						(Login_user && Login_user.users_type == 'accounts') ||
						(AllowAnyOforGetRoleNType(['admin']) && Login_user && Login_user.users_type == 'sales')
					"
					class="flex d-flex align-center justify-content-end pr-1"
				>
					<div class="mr-2 fw-600">Filter By</div>
					<div class="ml-3 filterTagSelect">
						<v-autocomplete
							:items="users"
							:menu-props="{ bottom: true, offsetY: true }"
							outlined
							hide-details
							hide-top-margin
							clearable
							class="filterSelect"
							@click:clear="filter = {}"
							item-text="display_name"
							:disabled="pageLoading"
							style="min-width: 270px !important; width: 270px !important"
							v-model="filter.user_filter"
							item-value="id"
							placeholder="Sales Person"
							@change="applyFilter"
						>
							<template v-slot:selection="data">
								<v-chip x-small v-bind="data.attrs" :input-value="data.selected">
									<v-avatar left v-if="data.item.profile_img">
										<v-img v-if="data.item.profile_img" :src="data.item.profile_img"></v-img>
										<v-img v-else :src="$assetURL('media/misc/no_photo_found.png')"></v-img>
									</v-avatar>

									{{ data.item.display_name }}
								</v-chip>
							</template>
							<template v-slot:item="data">
								<template>
									<v-list-item-avatar>
										<img v-if="data.item.profile_img" :src="data.item.profile_img" />
										<v-img v-else :src="$assetURL('media/misc/no_photo_found.png')"></v-img>
									</v-list-item-avatar>
									<div class="fw-500 text-uppercase">
										<p>{{ data.item.display_name }}</p>
										<span class="text-muted text-lowercase">{{ data.item.email }}</span>
									</div>
								</template>
							</template>
						</v-autocomplete>
					</div>
				</v-flex>
				<v-btn
					v-if="getPermission('customer:create')"
					depressed
					:disabled="pageLoading"
					tile
					color="red darken-4"
					class="text-white"
					:to="{
						name: 'customer-create',
						query: {
							tab: 'overview',
						},
						query: { t: new Date().getTime() },
					}"
				>
					<v-icon left> mdi-plus </v-icon>
					Create
				</v-btn>

				<!-- <v-menu
						bottom
						left
						v-model="dragCheck"
						origin="center center"
						transition="slide-y-transition"
						rounded="0"
						offset-y
						max-height="400px"
						:close-on-content-click="false"
						content-class="white-background"
					>
						<v-list class="py-0">
							<template v-for="(item, i) in listingAction">
								<v-list-item link :key="i" v-on:click="doAction(item.value)">
									<v-list-item-title v-on:click="referess"
										><v-icon v-if="item.icon" left :color="item.color">{{ item.icon }}</v-icon
										>{{ item.title }}</v-list-item-title
									>
								</v-list-item>
								<v-divider v-if="listingAction[i + 1]" :key="`d` + i" class="m-0 p-0"></v-divider>
							</template>
						</v-list>
					</v-menu> -->

				<v-menu
					v-model="bulkCheck"
					bottoms
					id="draggable-columns"
					left
					origin="center center"
					transition="slide-y-transition"
					rounded="0"
					offset-y
					max-height="400px"
					:close-on-content-click="false"
					content-class="white-background"
				>
					<template v-slot:activator="{ on, attrs }">
						<v-btn
							depressed
							color="blue darken-4"
							class="text-white"
							tile
							:disabled="pageLoading"
							v-bind="attrs"
							v-on="on"
						>
							<v-icon>mdi-table-edit</v-icon>
						</v-btn>
					</template>
					<Draggable
						v-if="bulkCheck"
						tag="ul"
						v-model="draggableThead"
						class="draggable-group"
						handle=".draggable-drag-icon"
						v-on:change="updateTable('customer')"
					>
						<template v-for="cols in draggableThead">
							<li class="draggable-group-item" :key="cols.key" v-if="!cols.checkbox">
								<v-checkbox
									dense
									v-model="draggableTheadShow"
									v-bind:value="cols.key"
									:label="cols.name"
									:disabled="cols.fixed || pageLoading"
									color="blue"
									hide-details
									class="mt-0 mb-0"
									v-on:change="updateTableVisiblity('customer')"
								></v-checkbox>
								<v-icon
									v-if="!pageLoading && !cols.fixed"
									class="draggable-action draggable-drag-icon"
									right
									color="blue"
									>mdi-drag</v-icon
								>
								<v-icon v-else right class="draggable-action" color="grey">mdi-drag</v-icon>
							</li>
						</template>
					</Draggable>
				</v-menu>
			</v-flex>
		</v-layout>
		<SearchCriteria
			v-if="search_Enabled()"
			:search-fields="listingFilter"
			search-string="Customer #, Name, Email, Phone, Website and Address"
			v-on:advance-search="advanceSearch = true"
			v-on:close-search="resetSearch"
		></SearchCriteria>
		<template v-if="addvanceFilterAarry.length > 0">
			<v-layout class="btx-search-criteria">
				<v-flex md10>
					<h6>Search Criteria</h6>
					<ul>
						<li>
							Advance Filter
							<span class="search-text"></span>
						</li>
					</ul>
				</v-flex>
				<v-flex md2 class="text-right">
					<v-btn
						depressed
						color="red lighten-1"
						class="text-white"
						tile
						text
						small
						v-on:click="statusFilterRows('all')"
					>
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</v-flex>
			</v-layout>
		</template>
		<template>
			<Table
				v-if="getPermission('customer:view')"
				type="customer"
				delete-endpoint="customer/"
				detail-route="customer-detail2"
				:page-loading="pageLoading"
				update-route="update-customer"
				v-on:reload:content="filterRows"
				:status-filter-data="statusFilterData"
			></Table>
		</template>

		<ExportDialog
			endpoint="contact/export?type=customer&current=1"
			title="Customers"
			current
			:export-dialog.sync="exportCurrentDialog"
			v-on:close="exportCurrentDialog = false"
		></ExportDialog>
		<ExportDialog
			endpoint="contact/export?type=customer"
			title="Customers"
			:export-dialog.sync="exportDialog"
			v-on:close="exportDialog = false"
		></ExportDialog>
		<ImportDialog
			endpoint="contact/import?type=customer"
			title="Customers"
			:import-dialog.sync="importDialog"
			v-on:close="importDialog = false"
		></ImportDialog>
		<FilterDialog
			title="Customers"
			:filter-dialog.sync="advanceSearch"
			:btx-filter.sync="listingFilter"
			:btx-query.sync="btxQuery"
			v-on:do-search="doAdvanceSearch"
			v-on:close="advanceSearch = false"
		>
		</FilterDialog>
		<AdvanceFilter
			v-on:addvanceFilterData="dataFilter"
			:advanceSearch="drawerFilter"
			v-on:close="drawerFilter = false"
			search-endpoint="customer"
			search-title="Customer"
		></AdvanceFilter>
	</v-sheet>
</template>

<script>
//import CustomerKanban from "@/view/module/contacts/customer/CustomerKanban";
import ListingMixin from "@/core/mixins/listing.mixin";
import SettingsMixin from "@/core/mixins/settings.mixin";
import {
	SET_TBODY,
	SET_SHOWING_STRING,
	SET_TOTAL_PAGE,
	SET_CURRENT_PAGE,
	SET_STATUS,
} from "@/core/services/store/listing.module";
import { QUERY } from "@/core/services/store/request.module";
import { RemarkEventBus } from "@/core/lib/remark.lib";
import { mapGetters } from "vuex";
import { getCurrentUser } from "@/core/services/jwt.service";
import objectPath from "object-path";

/* window.addEventListener("load", (evt) => {
	console.log("load => ", evt);
}); */

export default {
	name: "customer-listing",
	title: "Listing Customer",
	mixins: [ListingMixin, SettingsMixin],
	data() {
		return {
			skipBottomHeight: 55,
			drawerFilter: false,
			userItems: ["Everyone"],
			filter_user: "Everyone",
			viewType: true,
			addvanceFilterAarry: [],
			pageTitle: "Customers",
			pageBreadcrumbs: [
				{ text: "Contacts", disabled: true },
				{ text: "Customer", disabled: true },
			],
			endpoint: "customer",
			settingsEndpoint: "customer-setting",
			statusFilterData: [],
			customerStatus: [],
			status: "all",
			query: null,
			bulkCheck: false,
			dragCheck: null,
			users: [],
			Login_user: null,
		};
	},
	methods: {
		referess: function () {
			window.location.reload();
		},
		dataFilter: function (value) {
			this.addvanceFilterAarry = value;
		},
		/* getStatusChipWidth(count) {
			switch (count) {
				case count < 100:
					return "36px";
				case count < 1000:
					return "43px";
				case count < 10000:
					return "51px";
				case count < 100000:
					return "58px";
				case count < 1000000:
					return "65px";
				case count < 10000000:
					return "72px";
			}
		}, */
		statusFilterRows(value) {
			this.allFiltersStatus(value);
			this.pageLoading = true;
			this.$store
				.dispatch(QUERY, {
					url: `customer`,
					data: {
						status: value,
					},
				})
				.then((data) => {
					this.$store.commit(SET_TBODY, data.tbody);
					this.$store.commit(SET_SHOWING_STRING, data.showing_string);
					this.$store.commit(SET_TOTAL_PAGE, data.total_page);
					this.$store.commit(SET_CURRENT_PAGE, 1);
					this.$store.commit(SET_STATUS, data.status);
					this.addvanceFilterAarry = [];
				})
				.catch((error) => {
					console.log({
						error,
					});
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		allFiltersStatus(value) {
			this.$router.push({
				name: "customer",
				query: {
					status: value,
					t: new Date().getTime(),
				},
			});
		},
		routeSetting() {
			this.$router.push({
				name: "setting",
			});
		},
		applyFilter() {
			const { user_filter } = this.filter;
			const { name, params, query } = this.$route;
			this.$router.push({ name, params, query: { ...query, user_filter } });
			this.getListing();
		},
	},
	computed: {
		...mapGetters(["listingStatus", "moduleSettings"]),
	},
	watch: {
		moduleSettings: {
			immediate: true,
			handler(settings) {
				if (Object.keys(settings).length) {
					this.users = objectPath.get(settings, "sales_users") || [];
					this.customerStatus = objectPath.get(settings, "status") || [];
				}
			},
		},
	},
	components: {
		//CustomerKanban,
		AdvanceFilter: () => import("@/view/pages/leads/AdvanceFilter"),
	},
	beforeMount() {
		const { query } = this.$route;
		if (Object.keys(query).length) {
			this.filter.user_filter = +query.user_filter || null;
		}
		this.Login_user = getCurrentUser() || null;
	},
	mounted() {
		RemarkEventBus.$on("table-refresh", () => {
			this.getListing();
		});
	},
};
</script>
